.main .collection {
  padding: 60px 0;
  background: #F5F5F5;
  position: relative;

  & > img {
    position: absolute;
    top: -200px;
    right: -100px;
    width: 250px;
    animation: rotating 5s linear infinite;
    

    @media screen and (max-width: 2000px){
      width: 200px;
      top: -150px;
    }
    @media screen and (max-width: 1440px){
      width: 150px;
      top: -100px;
    }
    @media screen and (max-width: 768px){
      top: -170px;
    }
    @media screen and (max-width: 470px){
      top: -110px;
      width: 100px;
    }
  }
  h1 {
    padding-bottom: 30px;
  }
  &-slider {
    padding: 20px 0;

    .collection-slide {
      padding: 0 10px;
      text-align: center;

      & > div {
        height: 400px;
        border-radius: 10px;
        overflow: hidden;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        @media screen and (max-width: 1300px){
          height: 300px;
        }
        @media screen and (max-width: 1024px){
          height: 220px;
        }
        @media screen and (max-width: 650px){
          height: 170px;
        }
      }
      p {
        padding-top: 10px;
        font-weight: 700;
        font-size: 30px;

        @media screen and (max-width: 1440px){
          font-size: 24px;
        }
      }
    }
    .collection-bottom {
      padding-top: 30px;
      width: max-content;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 30px;

      button {
        border: 1px solid #444;
        border-radius: 50%;
        width: 45px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;

        svg {
          stroke: #444;
        }
      }
    }
  }
}