.error {
  width: 100%;
  padding: 30px;
  text-align: center;

  img {
    width: 80px;
  }
  h1 {
    font-size: 30px;
  }
}