.contacts {
  header {
    fill: #000;
  }
  .info {
    padding: 30px;
    h1 {
      text-align: center;
      padding-bottom: 50px;
    }

    &-inner {
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 768px){
        flex-wrap: wrap;
      }

      .info-text {
        width: 50%;
        display: flex;
        gap: 20px;
        flex-direction: column;

        @media screen and (max-width: 768px){
          width: 100%;
        }
        .info-item {
          display: flex;
          align-items: center;
          gap: 20px;
          row-gap: 10px;

          @media screen and (max-width: 900px){
            flex-wrap: wrap;
          }
          @media screen and (max-width: 768px){
            flex-wrap: nowrap;
          }
          @media screen and (max-width: 600px){
            flex-wrap: wrap;
          }

          p {
            font-weight: 600;
            color: #000000;
          }

          svg {
            flex-basis: 40px;
          }

          a {
            font-weight: 400;
            color: #454545;

            @media screen and (max-width: 900px){
              width: 100%;
            }
            @media screen and (max-width: 768px){
              width: max-content;
            }
            @media screen and (max-width: 768px){
              width: 100%;
            }
          }
          &:last-child {
            display: block;

            & > div {
              display: flex;
              gap: 20px;

              a {
                width: max-content;
              }
            }
            p {
              padding-bottom: 10px;
            }
          }
        }
      }
      .info-map {
        width: 50%;

        @media screen and (max-width: 768px){
          width: 100%;
        }
        iframe {
          width: 100%;
        }
      }
    }
  }

  .contacts-bottom {
    position: relative;
    padding: 50px 0 80px;

    @media screen and (max-width: 768px){
      padding: 80px 0 100px;
    }

    .contacts-left {
      position: absolute;
      top: -240px;
      width: 150px;
      left: -100px;
      animation: rotating 5s linear infinite;


      @media screen and (max-width: 1440px){
        top: -120px;
        width: 200px;
      }
      @media screen and (max-width: 768px){
        top: -80px;
      }
      @media screen and (max-width: 508px){
        width: 138px;
      }
    }
    .contacts-right {
      position: absolute;
      bottom: 100px;
      right: -200px;
      width: 600px;
      animation: rotating 5s linear infinite;

      @media screen and (max-width: 1300px) {
        width: 160px;
      }
      @media screen and (max-width: 768px){
        width: 100px;
        bottom: -20px;
      }
      @media screen and (max-width: 450px){
        width: 80px;
      }
    }
  }
}