.main .events {
  padding: 50px 0 150px;

  .events-slider {
    display: flex;
    align-items: center;
  }

  .container {
    max-width: 95%;
  }

  h1 {
    padding-bottom: 50px;
  }
  .slick-slider {
    background: #ffffff;
    box-shadow: 0 4px 64px rgba(0, 0, 0, 0.1);
  }
  .event {
    display: flex;
    align-items: center;
    gap: 20px;
    height: 427px;
    @media screen and (max-width: 850px){
      flex-direction: column-reverse;
    }
    @media screen and (max-width: 550px){
      gap: 0;
    }

    &-text {
      padding: 30px;
      text-align: start;

      @media screen and (max-width: 550px){
        padding: 20px;
      }
      h3 {
        font-size: 52px;
        font-weight: 700;

        span {
          color: rgba(9, 9, 160, 1);
        }
        @media screen and (max-width: 1850px){
          font-size: 42px;
        }
        @media screen and (max-width: 1440px){
          font-size: 35px;
        }
        @media screen and (max-width: 1300px){
          font-size: 30px;
        }
        @media screen and (max-width: 550px){
          font-size: 24px;
        }
      }
      p {
        font-weight: 400;
        font-size: 23px;
        padding-bottom: 40px;

        @media screen and (max-width: 1440px) {
          font-size: 20px;
        }
        @media screen and (max-width: 1300px) {
          font-size: 16px;
          padding-bottom: 20px;
        }
        @media screen and (max-width: 550px){
          font-size: 15px;
        }
      }
    }

    @media screen and (max-width: 1850px){
      img {
        width: 50%;
      }
    }
    @media screen and (max-width: 850px){
      img {
        width: 100%;
      }
    }
  }

  .arrowprev {
    cursor: pointer;
  }
  .arrownext {
    cursor: pointer;
  }
}