.catalog {
  position: relative;

  > img {
    position: absolute;

    &.catalog-left {
      top: 300px;
      left: 0;
      animation: rotating 5s linear infinite;

      
      // @media screen and (max-width: 1850px) {
        width: 200px;
        top: 350px;
        left: -100px;
      // }
      @media screen and (max-width: 1300px) {
        width: 50px;
        top: 420px;
      }
    }
    &.catalog-right {
      top: 340px;
      right: 0;
      animation: rotating 5s linear infinite;
      // @media screen and (max-width: 1850px){
        width: 200px;
        top: 350px;
        right: -70px;
      // }
      @media screen and (max-width: 1300px) {
        width: 50px;
        top: 450px;
      }
    }
    &.catalog-bottom-right {
      bottom: 0;
      right: 0;
      animation: rotating 5s linear infinite;
      // @media screen and (max-width: 1850px){
        right: -100px;
        width: 200px;
      // }
    }
  }
  .title {
    padding: 40px 0;
  }
  &-banner {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 300px;
    padding: 30px;
    margin: 40px 0;

    h6 {
      font-size: 35px;
      padding: 40px 0 10px 100px;

      @media screen and (max-width: 1850px) {
        font-size: 25px;
        padding-left: 50px;
      }
      @media screen and (max-width: 1000px) {
        font-size: 20px;
        padding-left: 0;
      }
      @media screen and (max-width: 768px) {
        text-shadow: 3px 2px 4px rgba(225, 225, 225, 0.79);
      }
    }
    h1 {
      font-size: 80px;
      padding-left: 100px;

      @media screen and (max-width: 1850px) {
        font-size: 50px;
        padding-left: 50px;
      }
      @media screen and (max-width: 1000px) {
        font-size: 35px;
        padding-left: 0;
      }
      @media screen and (max-width: 768px) {
        text-shadow: 3px 2px 4px rgba(225, 225, 225, 0.79);
      }
    }
  }

  &-inner {
    position: relative;
    display: flex;
    gap: 20px;
    padding: 20px 0;

    .catalog-content {
      position: relative;
      z-index: 3;
      width: 100%;

      .catalog-top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 768px) {
          flex-direction: column;
          align-items: start;
        }
        > div {
          display: flex;
          align-items: center;
          gap: 10px;
        }
        .filter-btn {
          display: none;

          svg {
            width: 25px;
            height: 25px;
            fill: #ED1D24;
          }
          @media screen and (max-width: 768px) {
            display: block;
          }
        }
      }
      //.product-list {
      //  grid-template-columns: 1fr 1fr;
      //}
    }
  }
}