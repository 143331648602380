.contact__title {
  text-align: center;
  margin-bottom: 20px;
}

.mySwiper {
  width: 100%;
}

.swiper-slide {
  width: 80%;
  text-align: center;
}