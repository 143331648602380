.services-page {
  position: relative;

  .stone-left {
    position: absolute;
    top: 120px;
    left: 0;
    width: 180px;
    animation: rotating 5s linear infinite;

    
    @media screen and (max-width: 1850px){
      width: 160px;
      top: 100px;
      left: -100px;
    }
    @media screen and (max-width: 768px){
      width: 60px;
    }
  }
  .stone-right {
    position: absolute;
    right: 0;
    top: 120px;
    width: 150px;
    animation: rotating 5s linear infinite;

    @media screen and (max-width: 1850px){
      width: 150px;
      right: -80px;
      top: 100px;
    }
    @media screen and (max-width: 768px){
      width: 50px;
    }
  }



  header {
    color: #fff;
    background: rgba(0, 0, 0, 0.73);
  }
  .title {
    padding: 50px 0 50px;
  }
  .services-info {
    padding: 20px 0;
    display: flex;
    align-items: center;
    gap: 20px;

    @media screen and (max-width: 1100px) {
      flex-direction: column;
    }
    .info-text {
      width: 50%;

      h2 {
        padding-bottom: 20px;
        font-weight: 700;
        font-size: 35px;
      }
      p {
        padding-bottom: 10px;
      }
      button {
        margin-top: 30px;
      }

      @media screen and (max-width: 1100px) {
        width: 80%;
      }
      @media screen and (max-width: 650px) {
        width: 100%;
      }
    }
    .info-img {
      width: 50%;

      @media screen and (max-width: 1100px) {
        width: 80%;
      }
      @media screen and (max-width: 650px) {
        width: 100%;
      }
      img {
        width: 100%;
      }
    }
  }
  .services-slider {
    padding: 40px;

    .slick-slide {
      padding: 20px;

      @media screen and (max-width: 768px) {
        padding: 5px;
      }
      @media screen and (max-width: 450px) {
        padding: 0;
      }
    }
    .slick-prev {
      left: 0;
      width: 40px;
      height: 40px;
    }
    .slick-next {
      right: 0;
      width: 40px;
      height: 40px;
    }
  }
  .social {
    width: max-content;
    margin: 0 auto;
    padding-bottom: 40px;

    > div {
      padding-top: 20px;
      display: flex;
      justify-content: center;
      gap: 20px;
      align-items: center;
    }
  }
  .services-text {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;

    @media screen and (max-width: 1300px) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 650px) {
      grid-template-columns: 1fr;
    }
    .text-item:first-child {
      grid-column-end: 3;
      grid-column-start: 1;
      background: #010E27;
      color: #fff;
      padding: 30px;
      padding-top: 0;

      @media screen and (max-width: 650px) {
        grid-column-end: 2;
      }
      p {
        padding: 0;
        padding-top: 20px;
      }
    }
    .text-item {
      background: #FFFFFF;
      box-shadow: 0 0 64px rgba(0, 0, 0, 0.1);

      &-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 200px;

        @media screen and (max-width: 1300px) {
          height: 130px;

          svg {
            width: 80px;
            height: 80px;
          }
        }
        @media screen and (max-width: 500px) {
          height: 100px;
          svg {
            width: 50px;
            height: 50px;
          }
        }
        h3 {
          font-size: 35px;
          font-weight: 700;

          span {
            font-weight: 400;
          }
          @media screen and (max-width: 500px) {
            font-size: 28px;
          }
        }
      }
      &-bg {
        background-image: url("../../assets/img/statics-bg.png");
        background-size: cover;
        background-repeat: no-repeat;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 200px;

        @media screen and (max-width: 1300px) {
          height: 130px;

          svg {
            width: 70px;
            height: 70px;
          }
        }
        @media screen and (max-width: 500px) {
          height: 100px;
          svg {
            width: 50px;
            height: 50px;
          }
        }
      }
      p {
        padding: 20px;
      }
     }
  }
  .consultation {
    margin: 50px 80px;
    max-width: 100%;

    @media screen and (max-width: 768px) {
      margin: 0;
    }
    form {
      max-width: 100%;

      textarea {
        max-width: 100%;
      }
    }
  }
  
  .services-text-wrapper {
    position: relative;

    > img {
      top: -300px;
      position: absolute;
      left: -90px;
      animation: rotating 5s linear infinite;


      width: 200px;
      @media screen and (max-width: 768px) {
        width: 100px;
        top: -250px;
      }
    }
  }

  .consultation-wrapper {
    position: relative;

    @media screen and (max-width: 768px) {
      padding: 100px 0 130px;
    }
    > img:first-child {
      position: absolute;
      top: 0;
      right: -60px;
      animation: rotating 5s linear infinite;


      @media screen and (max-width: 2000px) {
        width: 170px;
      }
      @media screen and (max-width: 1170px) {
        width: 90px;
      }
    }
    > img:nth-child(2) {
      position: absolute;
      bottom: 0;
      left: -60px;
      animation: rotating 5s linear infinite;


      @media screen and (max-width: 2000px) {
        width: 180px;
      }
      @media screen and (max-width: 1550px) {
        width: 150px;
      }
      @media screen and (max-width: 1170px) {
        width: 100px;
      }
      @media screen and (max-width: 768px) {
        width: 80px;
      }
    }
  }
}