.swiper-button-next,.swiper-button-prev {
  color: #000 !important;
}

.youtubevideo {
  width: 788px;
  height: 685px;
  @media (max-width: 808px) {
    width: 100%;
  }
}