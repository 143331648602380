.product-slider {
  width: 700px;
  flex-direction: column;

  @media screen and (max-width: 1440px){
    width: 500px;
  }
  @media screen and (max-width: 1000px){
    width: 100%;
  }

  .main-slider {
    width: 100%;
    // height: 500px;
    margin-bottom: 20px;

    @media screen and (max-width: 1440px){
      height: 400px;
    }
    @media screen and (max-width: 1000px){
      height: 430px;
    }
    @media screen and (max-width: 550px){
      height: 380px;
    }
    .product-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 30px;

      > div {
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        height: 400px;
        width: 100%;
      }
    }
  }

  .slides {
    width: 100%;
    overflow: hidden;

    .product-slide {
      width: 210px;
      height: 130px;
      padding: 15px;
      display: flex;
      justify-content: center;
      align-items: center;


      > div {
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        height: 100%;
        width: 100%;
      }
    }
  }
}