footer {
  padding: 10px 0 20px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .footer-inner {
    display: flex;
    justify-content: space-between;
    gap: 25px;
    color: #fff;

    .footer-nav {
      display: flex;
      justify-content: space-between;
      gap: 20px;

      @media screen and (max-width: 400px) {
        flex-direction: column;
        padding-top: 30px;
      }

      .nav-item {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        h3 {
          font-weight: 600;
          font-size: 18.6529px;
          padding-bottom: 10px;
          text-transform: uppercase;

          @media screen and (max-width: 1300px) {
            font-size: 16px;
          }
        }

        a {
          font-weight: 400;
          font-size: 16px;

          @media screen and (max-width: 1300px) {
            font-size: 15px;
          }
        }
      }
    }

    .footer-item {
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      color: rgba(161, 154, 154, 1);

      a {
        display: flex;
        align-items: center;
        gap: 10px;

        p {
          line-height: 10px;
        }

        span {
          font-weight: 600;
          font-size: 10px;
          text-transform: uppercase;
          padding-left: 5px;
        }
      }

      svg {
        &.phone {
          stroke: rgba(161, 154, 154, 1);
        }

        &.email {
          fill: rgba(161, 154, 154, 1);
        }
      }

      button {
        margin: 10px 0;
        width: max-content;
        margin: 0 auto;
      }

      > p {
        text-align: center;
      }

      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }

    }

    .logo {
      height: max-content;
    }

    @media screen and (max-width: 1440px) {
      gap: 15px;

      .logo svg {
        width: 200px;
      }
    }
    @media screen and (max-width: 950px) {
      gap: 20px;
      flex-wrap: wrap;
      justify-content: left;

      .footer-nav {
        width: 100%;
      }
      .footer-item {
        order: 3;
      }
      .logo {
        order: 2;
      }
    }
  }
}


/* The Modal (background) */
.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  a {
    margin: 0 auto;
    max-width: fit-content;
  }
  &_content {
    display: flex;
    position: relative;
    align-items: center;
    svg {
      position: absolute;
      cursor: pointer;
      right: 0;
      width: 30px;
      top: 0;
    }
  }
}


.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}