.product-list {
  row-gap: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  flex-wrap: wrap;
  padding: 10px 0 30px;

  @media screen and (max-width: 1050px){
    grid-template-columns: 1fr 1fr;
  }
  .product {
    text-align: center;
    padding: 0 10px;

    img {
      width: 100%;
      height: 100%;
      max-width: 320px;
      max-height: 320px;
    }

    h3 {
      font-size: 20px;
      font-weight: 500;

      @media screen and (max-width: 800px){
        font-size: 20px;
      }
      @media screen and (max-width: 500px){
        font-size: 13px;
      }
      
    }
    p {
      font-size: 18px;
      font-weight: 400;
      color: rgba(46, 48, 61, 0.72);

      @media screen and (max-width: 900px){
        font-size: 16px;
      }
    }

    @media screen and (max-width: 500px) {
      padding: 0px 5px;
    }
    
  }
}