.empty {
  width: 100%;
  text-align: center;

  h1 {
    font-size: 30px;
  }
  img {
    width: 100px;
  }
}