header {
  .nav-list {
    display: flex;
    gap: 40px;
    font-size: 18px;

    @media screen and (max-width: 1850px){
      gap: 15px;
      font-size: 15px;
    }
  }

  @media screen and (max-width: 950px){
    .header-nav {
      display: none;
    }
  }
}