

.main .statics {
  padding: 100px 0 80px;
  position: relative;
  background-size: 40%;
  background-position: right center;
  background-repeat: no-repeat;

  @media screen and (max-width: 768px) {
    padding-bottom: 0;
  }

  @media screen and (max-width: 1440px){
    background-size: 43%;
  }
  h1 {
    padding-bottom: 70px;
  }
  & > img {
    position: absolute;
  }
  .stone-left {
    width: 203px;
    left: -127px;
    top: -140px;
    animation: rotating 5s linear infinite;


    @media screen and (max-width: 1440px){
      width: 100px;
      top: -60px;
    }
    @media screen and (max-width: 768px) {
      width: 90px;
      top: -100px;
    }
  }
  .stone-right {
    width: 200px;
    top: -120px;
    right: -109px;

    animation: rotating 5s linear infinite;


    @media screen and (max-width: 1440px){
      width: 170px;
      top: -150px;
    }
    @media screen and (max-width: 1200px){
      width: 130px;
    }
    @media screen and (max-width: 768px) {
      width: 100px;
    }
  }

  &-inner {
    display: flex;
    gap: 8%;
    align-items: center;

    @media screen and (max-width: 1440px){
      gap: 5%;
    }
    @media screen and (max-width: 768px){
      flex-direction: column;
      gap: 30px;
    }

    .statics-img {
      padding: 50px;
      padding-left: 0;
      background-repeat: no-repeat;
      background-size: cover;
      width: 55%;

      @media screen and (max-width: 1024px){
        flex-grow: 1;
        padding: 20px;
        padding-left: 0;
      }
      @media screen and (max-width: 768px){
        width: 100%;
        padding: 20px;
      }
      img {
        width: 100%;
      }
    }
    .statics-text {
      width: 40%;
      padding-right: 80px;

      @media screen and (max-width: 1024px){
        padding-right: 50px;
      }
      @media screen and (max-width: 768px){
        width: 100%;
        padding: 20px;
      }

      h3 {
        font-size: 65px;
        font-weight: 600;
        padding-bottom: 10px;

        @media screen and (max-width: 1440px){
          font-size: 40px;
        }
      }
      p {
        padding-bottom: 30px;
      }
    }
  }
}