.consultation {
  max-width: 675px;
  padding: 30px;
  color: #000;
  background: #FFFFFF;
  box-shadow: 4px 4px 25px -9px rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 1440px){
    padding: 20px;
  }

  h1 {
    font-weight: 900;
    font-size: 37px;
    padding-bottom: 10px;
    text-align: center;

    @media screen and (max-width: 1440px){
      font-size: 28px;
    }
  }
  .subtitle {
    font-weight: 400;
    font-size: 20px;
    padding-bottom: 30px;
    text-align: center;

    @media screen and (max-width: 1440px){
      font-size: 18px;
    }
  }
  form {
    max-width: 400px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 380px) {
      max-width: 260px;
    }

    label {
      font-weight: 400;
      font-size: 15.6597px;
      line-height: 19px;
      color: rgba(46, 48, 61, 0.72);
      padding-bottom: 10px;
    }

    input, textarea {
      padding: 15px;
      font-weight: 400;
      font-size: 15.6597px;
      margin-bottom: 30px;
      color: rgba(46, 48, 61, 0.72);
      background: #F5F5F5;
      border: none;

      @media screen and (max-width: 1440px){
        margin-bottom: 20px;
      }
    }
    textarea {
      max-width: 400px;
      min-width: 300px;
      max-height: 300px;
      min-height: 100px;

      @media screen and (max-width: 400px) {
        min-width: 240px;
      }
    }
    button {
      max-width: 300px;
      margin: 0 auto;
    }
    .subtext {
      padding-top: 20px;
      font-weight: 400;
      font-size: 13px;
      line-height: 15px;
      text-align: center;
      color: rgba(46, 48, 61, 0.72);

      a {
        text-decoration: rgba(46, 48, 61, 0.72);
      }
    }
  }
}