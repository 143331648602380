.App {
  text-align: center;
}

#root {
  overflow: hidden;
}

.blogImage {
  float: left;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.blog__title {
  font-size: 26px !important;
}

.blog__wrap {
  img {
    width: 670px;
    height: 453px;
    margin: 0px 10px;
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


.whatsappbutton {
  z-index: 999999999;
  position: fixed;
  bottom: 95px;
  right: 20px;
  width: fit-content;
  cursor: pointer;
  img {
    width: 70px;
    height: 70px;
  }
}
.blog__wrap{
  max-height: 700px;
  height: 700px;
}

.portfolio__main__slider {
  width: 86% !important;
  height: 100% !important;
  .product-slider {
    height: 100%;
  }
  .product-slide  {
    height: 100% !important;
    div {
      width: 100%;
      height: 100% !important;
      background-size: cover !important;
    }
  }
  .slick-list {
    height: 100% !important;
    .slick-track {
      height: 100% !important;
      * {
        height: 100% !important;
      }
    }
  }
}

.portfolio__slider {
  display: flex;
  flex-direction: row !important;
  width: 100% !important;
  height: 100% !important;
}


.portfolio__minislider {
  height: 100% !important;
}

.product-slider {
  display: flex;
  align-content: center;
}